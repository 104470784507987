<template>
    <div class="d-flex justify-content-center align-items-center">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: "index"
}
</script>

<style scoped>

</style>